import React, { PureComponent } from 'react';
import set from 'lodash/set';
import property from 'lodash/property';
import { HotTable, HotTableProps } from '@handsontable/react';
import { formatNumberToNumber } from 'helpers/utils';

import Handsontable from 'handsontable';
import classes from '../../../AnnualPlanEdit.module.scss';

import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';
import { IPlatformConfig } from 'routes/AnnualPlanEdit/types';
import { RowErrors, Validator } from '../OlvCampaignParams';
import { discountDefaultValue } from '../../../../helpers/olv';

interface InnerPlatformConfig {
  name: string;
  restrictions: {
    customColumns: Record<string, { value: string; colName: string }>;
    advertiserBasePrice?: number;
    averageFrequency?: number;
    bigCitiesShare?: number;
    budgetPercent: { min: number; max: number };
    isExcludePlatform: boolean;
    maxReachPercent?: number;
    quality?: number;
    realUserConversion?: number;
    seasonalDiscounts: number[];
    targetHit: { targetHitValue?: number };
  };
}
const percentColumnIDs = [
  'restrictions.budgetPercent.min',
  'restrictions.budgetPercent.max',
  'restrictions.targetHit.targetHitValue',
  // eslint-disable-next-line max-len
  // 'restrictions.targetHit.universePart', 'restrictions.targetHit.maxHitPercent', 'restrictions.targetHit.avgHitPercent',
  'restrictions.bigCitiesShare',
  'restrictions.maxReachPercent'
];
const qualityColumnId = 'restrictions.quality';
const bigNumberColumnIDs = [
  qualityColumnId,
  'restrictions.averageFrequency',
  'restrictions.realUserConversion'
];
const numberColumnIDs = [...percentColumnIDs, ...bigNumberColumnIDs];
export const restrictionsNullableProps = [...numberColumnIDs];
const validableColumnIDs = [...numberColumnIDs, 'name'];
const HtPercentFrormatPattern: any = {
  postfix: '%',
  mantissa: 2,
  trimMantissa: true
};

const HtNumberFrormatPattern: any = {
  mantissa: 2,
  trimMantissa: true
};

interface IProps {
  isMainTa?: boolean;
  platforms: IPlatformConfig[];
  areExcludePlatformsVisible: boolean;
  onUpdatePlatforms: (platforms: IPlatformConfig[]) => void;
  validate: Validator;
}

class Restrictions extends PureComponent<IProps> {
  cellsErrors: RowErrors[] = [];
  _hot: any = null;
  minColumnIsValid: boolean = false;
  maxColumnIsValid: boolean = false;

  data: InnerPlatformConfig[] = [];

  constructor (props) {
    super(props);
    this.data = this.getInnerPlatforms(props);
  }

  componentDidMount () {
    this.forceUpdate();

    this.data = this.getInnerPlatforms();
    this.toggleRowVisibility();
  }

  setHotRef = ref => {
    this._hot = ref;
  };

  UNSAFE_componentWillReceiveProps (
    nextProps: Readonly<IProps>,
    nextContext: any
  ) {
    this.data = this.getInnerPlatforms(nextProps);
  }

  componentDidUpdate (
    prevProps: Readonly<IProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ) {
    if (this.props.areExcludePlatformsVisible !== prevProps.areExcludePlatformsVisible) {
      this.toggleRowVisibility();
    }
  }

  toggleRowVisibility (): void {
    const plugin = this._hot?.hotInstance.getPlugin('hiddenRows');
    if (!plugin) return;

    plugin.enablePlugin();

    const hiddenRows = !this.props.areExcludePlatformsVisible
      ? this.data
        .map((row, rowIndex) => (row.restrictions.isExcludePlatform ? rowIndex : null))
        .filter(row => row !== null)
      : [];
    plugin.hideRows(hiddenRows);

    this._hot.hotInstance.render();
  }

  getInnerPlatforms (props = this.props): InnerPlatformConfig[] {
    const { platforms } = props;

    return platforms.map(p => {
      const customs = p.restrictions.customColumns || {
        Test: 'value'
      };

      const customColumns = {};
      Object.keys(customs).forEach((key, index) => {
        customColumns[`custom_${index}`] = {
          value: customs[key],
          colName: key
        };
      });

      return {
        ...p,
        restrictions: {
          ...p.restrictions,
          customColumns
        }
      };
    });
  }

  getHeaderList (): [
    string[],
    { key: string; value: string; colName: string }[]
  ] {
    const additionalHeaders = Object.entries(
      this.data[0]?.restrictions.customColumns || {}
    ).map(([key, value]) => ({
      ...value,
      key
    }));

    return [
      [
        localizeMessage({ id: 'platform' }),
        'Min',
        'Max',
        localizeMessage({ id: 'annualPlanEdit.OLVCampaignParams.quality' }),
        localizeMessage({ id: 'annualPlanEdit.OLVCampaignParams.HitTA' }),
        // localizeMessage({id: 'annualPlanEdit.OLVCampaignParams.UniversePart'}),
        // localizeMessage({id: 'annualPlanEdit.OLVCampaignParams.MaxHitPercent'}),
        // localizeMessage({id: 'annualPlanEdit.OLVCampaignParams.AverageHitPercen'}),
        localizeMessage({
          id: 'annualPlanEdit.OLVCampaignParams.bigCitiesShare'
        }),
        localizeMessage({
          id: 'annualPlanEdit.OLVCampaignParams.averageFrequency'
        }),
        localizeMessage({
          id: 'annualPlanEdit.OLVCampaignParams.userConversion'
        }),
        localizeMessage({ id: 'annualPlanEdit.OLVCampaignParams.maxReach' }),
        localizeMessage({
          id: 'annualPlanEdit.OLVCampaignParams.restrictions.exclude'
        }),
        ...additionalHeaders.map(it => it.colName)
      ],
      additionalHeaders
    ];
  }

  generateTableOptions = (): HotTableProps => {
    const [headerList, additionalHeaders] = this.getHeaderList();

    return {
      data: this.data,
      // colHeaders: true,
      colHeaders (col) {
        return headerList[col];
      },
      manualColumnResize: [200, 80, 80, 120],
      outsideClickDeselects: false,
      // colHeaders: headerList,
      columns: [
        {
          data: 'name'
        },
        {
          data: 'restrictions.budgetPercent.min',
          type: 'numeric',
          readOnly: !this.props.isMainTa,
          numericFormat: {
            pattern: HtPercentFrormatPattern
          }
        },
        {
          data: 'restrictions.budgetPercent.max',
          type: 'numeric',
          readOnly: !this.props.isMainTa,
          numericFormat: {
            pattern: HtPercentFrormatPattern
          }
        },
        {
          data: 'restrictions.quality',
          type: 'numeric',
        },
        {
          data: 'restrictions.targetHit.targetHitValue',
          type: 'numeric',
          numericFormat: {
            pattern: HtPercentFrormatPattern
          }
        },
        // {
        //   data: 'restrictions.targetHit.universePart',
        //   type: 'numeric',
        //   numericFormat: {
        //     pattern: {
        //       postfix: '%',
        //       mantissa: 2,
        //       trimMantissa: true,
        //     },
        //   },
        // },
        // {
        //   data: 'restrictions.targetHit.maxHitPercent',
        //   type: 'numeric',
        //   numericFormat: {
        //     pattern: {
        //       postfix: '%',
        //       mantissa: 2,
        //       trimMantissa: true,
        //     },
        //   },
        // },
        // {
        //   data: 'restrictions.targetHit.avgHitPercent',
        //   type: 'numeric',
        //   numericFormat: {
        //     pattern: {
        //       postfix: '%',
        //       mantissa: 2,
        //       trimMantissa: true,
        //     },
        //   },
        // },
        {
          data: 'restrictions.bigCitiesShare',
          type: 'numeric',
          numericFormat: {
            pattern: HtPercentFrormatPattern
          }
        },
        {
          data: 'restrictions.averageFrequency',
          type: 'numeric',
          numericFormat: {
            pattern: HtNumberFrormatPattern
          }
        },
        {
          data: 'restrictions.realUserConversion',
          type: 'numeric',
          numericFormat: {
            pattern: HtNumberFrormatPattern
          }
        },
        {
          data: 'restrictions.maxReachPercent',
          type: 'numeric',
          numericFormat: {
            pattern: HtPercentFrormatPattern,
            culture: 'en-EN'
          }
        },
        {
          data: 'restrictions.isExcludePlatform',
          type: 'checkbox'
        },
        ...additionalHeaders.map(it => ({
          data: `restrictions.customColumns.${it.key}.value`
        }))
      ],
      cells: (rowIndex, columnIndex, columnID: string) => {
        const cellProperties: any = {
          className: '',
          readOnly: false,
          errorIDs: this.cellsErrors[rowIndex]
            ? this.cellsErrors[rowIndex][columnID] || []
            : []
        };

        const isColumnDisabled = !this.props.isMainTa && [
          'restrictions.isExcludePlatform',
          'restrictions.budgetPercent.min',
          'restrictions.budgetPercent.max',
        ].includes(columnID);
        const isRowDisabled = property('restrictions.isExcludePlatform')(this.props.platforms[rowIndex]);

        if (isColumnDisabled || (isRowDisabled && columnID !== 'restrictions.isExcludePlatform')) {
          cellProperties.readOnly = true;
          cellProperties.className = 'htDisabled';
        }

        if (columnID === 'restrictions.isExcludePlatform') {
          cellProperties.renderer = this.excludePlatformColumnRenderer;
        } else if (validableColumnIDs.includes(columnID)) {
          cellProperties.renderer = this.commentsRenderer;
        }

        if (cellProperties.errorIDs.length) {
          cellProperties.className += ' invalid';
        }

        return cellProperties;
      }
    };
  };

  excludePlatformColumnRenderer = (...args) => {
    Handsontable.renderers.CheckboxRenderer.apply(this, args);
  };

  commentsRenderer = (...args) => {
    Handsontable.renderers.NumericRenderer.apply(this, args);
    // [instance, td, row, col, prop, value, cellProperties]
    const [, td, , , , , cellProperties] = args;
    const msg = cellProperties.errorIDs
      .map(id => localizeMessage({ id }))
      .join('\n');
    td.setAttribute('title', msg);
  };

  validateFormMinColumnSum = () => {
    const { platforms } = this.props;
    let sum = platforms.reduce((prevSum, { restrictions }) => {
      const { budgetPercent, isExcludePlatform } = restrictions;

      return isExcludePlatform ? prevSum : prevSum + (budgetPercent.min || 0);
    }, 0);

    sum = Math.round(sum * 100) / 100;

    this.minColumnIsValid = sum <= 100;
  };

  validateFormMaxColumnSum = () => {
    const { platforms } = this.props;
    let sum = platforms.reduce((prevSum, { restrictions }) => {
      const { budgetPercent, isExcludePlatform } = restrictions;

      return isExcludePlatform ? prevSum : prevSum + (budgetPercent.max || 0);
    }, 0);

    sum = Math.round(sum * 100) / 100;

    this.maxColumnIsValid = sum >= 100;
  };

  validateCell = (row, columnID) => {
    const platformNames = this.props.platforms.map(platform => platform.name || '');
    const errorIDs: string[] = [];
    const stringValue = property<any, string>(columnID)(row);

    if (columnID === 'name') {
      if (!stringValue) {
        errorIDs.push('annualPlanEdit.errors.emptyField');
      } else if (
        platformNames.filter(name => name.toLocaleLowerCase() === stringValue.toLocaleLowerCase()).length > 1
      ) {
        errorIDs.push('annualPlanEdit.errors.uniqFieldValue');
      } else if (stringValue.length > 255) {
        errorIDs.push('annualPlanEdit.errors.valueLonger255');
      }
    } else if (numberColumnIDs.includes(columnID)) {
      const value = parseFloat(stringValue);
      if (Number.isNaN(value) || Number.isNaN(Number(stringValue))) {
        // parseFloat считает строку `123asdas` за число и вторая проверка на isNaN это фиксит
        errorIDs.push('annualPlanEdit.errors.notNumber');
      } else {
        if (
          [
            'restrictions.budgetPercent.min',
            'restrictions.budgetPercent.max'
          ].includes(columnID)
        ) {
          if (columnID === 'restrictions.budgetPercent.min') {
            const maxValue = property<any, any>(
              'restrictions.budgetPercent.max'
            )(row);
            if (parseFloat(maxValue) < value) {
              errorIDs.push('annualPlanEdit.errors.minExceedMax');
            }
            if (!this.minColumnIsValid) {
              errorIDs.push('annualPlanEdit.errors.columnSumExceed100');
            }
          } else {
            const minValue = property<any, any>(
              'restrictions.budgetPercent.min'
            )(row);
            if (minValue > value) {
              errorIDs.push('annualPlanEdit.errors.minExceedMax');
            }
            if (!this.maxColumnIsValid) {
              errorIDs.push('annualPlanEdit.errors.columnSumNotExceed100');
            }
          }
        }
        if (percentColumnIDs.includes(columnID)) {
          const isTargetHitOrGeoTargeting = [
            'restrictions.bigCitiesShare',
            'restrictions.targetHit.targetHitValue',
          ].includes(columnID);

          if (value > 100) {
            errorIDs.push('annualPlanEdit.errors.exceed100percent');
          } else if (value < 0) {
            errorIDs.push('annualPlanEdit.errors.notPositive');
          } else if (isTargetHitOrGeoTargeting && value === 0) {
            errorIDs.push('annualPlanEdit.errors.equalZero');
          }
        }
        if (bigNumberColumnIDs.includes(columnID)) {
          if (value > 99999) {
            errorIDs.push('annualPlanEdit.errors.exceed99999');
          } else if (value < 0 || (columnID === qualityColumnId && value <= 0)) {
            errorIDs.push('annualPlanEdit.errors.notPositive');
          }
        }
      }
    }

    return errorIDs;
  };

  checkAllExcludedPlatforms = () => this.props.platforms.some(row => row.restrictions.isExcludePlatform);

  onExcludeAllPlatformsSwitch = e => {
    const hasAllExcludedPlatforms = this.checkAllExcludedPlatforms();

    this.props.onUpdatePlatforms(this.props.platforms.map(it => ({
      ...it,
      restrictions: {
        ...it.restrictions,
        isExcludePlatform: !hasAllExcludedPlatforms
      }
    })));
  };

  makeExcludeButton = () => {
    const _button = document.createElement('button');
    _button.classList.add('htButton');
    _button.addEventListener(
      'click',
      this.onExcludeAllPlatformsSwitch.bind(this)
    );
    _button.innerText = !this.checkAllExcludedPlatforms()
      ? 'Select all'
      : 'Unselect all';

    return _button;
  };

  makeRemoveButton = (col: any, th: any) => {
    const button = document.createElement('button');
    const [headerList, additional] = this.getHeaderList();

    const key = additional[headerList.length - col - additional.length];

    button.classList.add('htButton');
    button.onclick = (e: any) => {
      this.data.forEach(pl => {
        if (pl.restrictions.customColumns) {
          delete pl.restrictions.customColumns[key.key];
        }
      });
      this.schedulePlatformsUpdate();
    };

    button.textContent = localizeMessage({
      id: 'annualPlanEdit.tvCampaignParams.restrictions.delete-column'
    });

    return button;
  };
  makeEditableColumn = (col: any, th: any) => {
    const input = document.createElement('input');

    const [headerList, additional] = this.getHeaderList();

    const value = headerList[col];

    const key = additional[headerList.length - col - additional.length];

    input.classList.add(classes.customColumn);
    input.onchange = (e: any) => {
      this.data.forEach(pl => {
        if (pl.restrictions.customColumns) {
          pl.restrictions.customColumns[key.key].colName = e.target.value;
        }
      });
      this.schedulePlatformsUpdate();
    };

    input.value = value;

    return input;
  };

  schedulePlatformsUpdate () {
    const platforms: IPlatformConfig[] = this.data.map(pl => {
      const customColumns = {};

      Object.values(pl.restrictions.customColumns).forEach(it => {
        customColumns[it.colName] = it.value;
      });

      return {
        ...pl,
        restrictions: {
          ...pl.restrictions,
          seasonalDiscounts: pl.restrictions.seasonalDiscounts.length
            ? pl.restrictions.seasonalDiscounts
            : new Array(12).fill(discountDefaultValue),
          customColumns
        }
      };
    });
    this.props.onUpdatePlatforms(platforms);
  }

  afterGetColHeader = (col, th) => {
    const columnsLength = this._hot ? this._hot.props.columns.length : null;
    const _colHeader = th.querySelector('.colHeader');

    const [, additional] = this.getHeaderList();

    if (_colHeader && columnsLength) {
      if (
        this.props.isMainTa &&
        col === columnsLength - 1 - additional.length &&
        _colHeader.childNodes.length === 1
      ) {
        _colHeader.append(this.makeExcludeButton());
      } else if (col === columnsLength - 3 + additional.length) {
        _colHeader.className += ' colHeader_wrap';
      } else if (additional.length > 0 && col === 9 + additional.length) {
        _colHeader.childNodes[0].remove();
        _colHeader.append(this.makeEditableColumn(col, th));
        _colHeader.append(this.makeRemoveButton(col, th));
      }
    }
  };

  afterChange = changes => {
    if (!changes) {
      return;
    }

    changes.forEach(([row, columnID, oldValue, newValue]) => {
      if (numberColumnIDs.includes(columnID) && typeof newValue === 'number') {
        set(this.data[row], columnID, columnID === qualityColumnId ? newValue : formatNumberToNumber(newValue));
      }
    });

    this.schedulePlatformsUpdate();
  };

  beforeRender = () => {
    this.validateFormMinColumnSum();
    this.validateFormMaxColumnSum();

    this.cellsErrors = this.props.validate('restrictions', validableColumnIDs, this.validateCell);
  };

  addColumn = () => {
    this.data.forEach(pl => {
      if (!pl.restrictions.customColumns) {
        pl.restrictions.customColumns = {};
      }
      pl.restrictions.customColumns[Math.random().toString()] = {
        colName: 'New column',
        value: ''
      };
    });
    this.schedulePlatformsUpdate();
    this.forceUpdate();
  };

  render () {
    const [, additional] = this.getHeaderList();

    return (
      <>
        <p className='_text-align--center m-t m-b'>
          <strong>
            <LocalizedMessage id='annualPlanEdit.tvCampaignParams.restrictions.budget' />
          </strong>
          {' '}
          <LocalizedMessage id='annualPlanEdit.tvCampaignParams.restrictions.end' />
          {' '}
          {additional.length === 0 && (
            <button onClick={this.addColumn} className='btn btn-white'>
              <LocalizedMessage id='annualPlanEdit.tvCampaignParams.restrictions.add-column' />
            </button>
          )}
        </p>

        <HotTable
          ref={this.setHotRef}
          {...this.generateTableOptions()}
          tableClassName='table'
          stretchH='all'
          width='100%'
          height='auto'
          autoWrapRow
          afterGetColHeader={this.afterGetColHeader}
          afterChange={this.afterChange}
          beforeRender={this.beforeRender}
          licenseKey='non-commercial-and-evaluation'
        />
      </>
    );
  }
}

export default Restrictions;
