import React, { PureComponent } from 'react';
import cx from 'classnames';
import { Helmet } from 'react-helmet';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';
import LangSwitcher from 'components/LangSwitcher';
import alert from 'helpers/alert';
import omit from 'lodash/omit';
import { RouteComponentProps } from 'react-router';

interface IOwnProps {

  isAuthorizing: boolean;
  isAuthorized: boolean;
  logIn: (mail: string, password: string) => void;
  checkAuth: () => void;
}

type IProps = RouteComponentProps & IOwnProps;

interface IState {
  mail: string;
  password: string;
  errors: Record<string, any>
}

class Auth extends PureComponent<IProps, IState> {
  state: IState = {
    mail: '',
    password: '',
    errors: {},
  };

  _form: any = null;

  componentDidMount () {
    this.props.checkAuth();
  }

  componentDidUpdate () {
    const { location, history, isAuthorized } = this.props;
    const stateFrom: any = location.state && (location.state as any).from;
    const from = stateFrom && stateFrom.pathname !== '/app/auth'
      ? stateFrom
      : {
        pathname: '/app',
        search: '',
        hash: '',
      };

    if (isAuthorized) {
      history.replace(from);
    }
  }

  setFormRef = (ref) => {
    this._form = ref;
  };

  onFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name: string = e.target.name;
    const value: string = e.target.value.trim();

    if (name === 'mail') {
      this.setState(prevState => ({
        ...prevState,
        mail: value,
        errors: omit(prevState.errors, 'mail'),
      }));
    }
    if (name === 'password') {
      this.setState(prevState => ({
        ...prevState,
        password: value,
        errors: omit(prevState.errors, 'password'),
      }));
    }
  };

  onSubmit = async e => {
    const { isAuthorizing, logIn } = this.props;
    const { mail, password } = this.state;

    e.preventDefault();

    if (isAuthorizing) {
      return;
    }

    const errors: any = {};

    if (!mail.length) {
      errors.mail = {
        message: <LocalizedMessage id='auth.errors.emptyField' />,
      };
    }

    if (!password.length) {
      errors.password = {
        message: <LocalizedMessage id='auth.errors.emptyField' />,
      };
    }

    if (Object.keys(errors).length) {
      this.setState({
        errors,
      });

      if (!mail.length) {
        this._form.mail.focus();
      } else {
        this._form.password.focus();
      }

      return;
    }

    try {
      await logIn(mail, password);
    } catch (error) {
      if (error.message === 'Failed to fetch') {
        alert.warn(localizeMessage({ id: 'errors.errorConnection' }));
      } else if (
        error.jsonResponse &&
        !error.jsonResponse.ok &&
        typeof error.jsonResponse.errors === 'object' &&
        Object.keys(error.jsonResponse.errors).length
      ) {
        const errorObj = error.jsonResponse.errors;
        if (errorObj.mail && errorObj.mail.message === 'Wrong credentials') {
          const msg = localizeMessage({ id: 'errors.errorWrongCredentials' });
          this.setState({ password: '', errors: { mail: { message: msg } } }, () => {
            this._form.mail.focus();
          });
          alert.warn(msg);
        } else {
          this.setState({ password: '', errors: error.jsonResponse.errors }, () => {
            this._form.mail.focus();
          });
          alert.warn(localizeMessage({ id: 'errors.unknownError' }));
        }
      } else {
        const { message } = error.jsonResponse;
        if (message.includes('Account is not fully set up')) {
          alert.error(localizeMessage({ id: 'errors.userDidNotSetUp' }));
        }

        if (message.includes('Account disabled')) {
          alert.error(localizeMessage({ id: 'errors.userDisabled' }));
        }

        alert.error(localizeMessage({ id: 'errors.badAuthData' }));
      }
    }
  };

  render () {
    const { mail, password, errors } = this.state;

    return (
      <div className='middle-box text-center loginscreen animated fadeInDown'>
        <LocalizedMessage
          id='site.title.auth'
        >
          {localizedMessage => (
            <Helmet
              title={localizedMessage}
            />
          )}
        </LocalizedMessage>
        <div className='_margin-top--50'>
          <h3>
            <LocalizedMessage
              id='auth.title'
            />
          </h3>

          <form
            ref={this.setFormRef}
            method='post'
            onSubmit={this.onSubmit}
            className='m-t'
          >
            <div
              className={cx(
                'form-group',
                {
                  'has-errors': errors.mail,
                },
              )}
            >
              <LocalizedMessage
                id='auth.form.email'
              >
                {localizedMessage => (
                  <input
                    type='text'
                    placeholder={localizedMessage}
                    name='mail'
                    value={mail}
                    onChange={this.onFieldChange}
                    className='form-control'
                    data-test='mail'
                  />
                )}
              </LocalizedMessage>
              {
                errors.mail ? (
                  <div
                    className='m-t-xs'
                  >
                    <span className='text-danger'>
                      {errors.mail.message}
                    </span>
                  </div>
                )
                  : null
              }
            </div>

            <div
              className={cx(
                'form-group',
                {
                  'has-errors': errors.password,
                },
              )}
            >
              <LocalizedMessage
                id='auth.form.password'
              >
                {localizedMessage => (
                  <input
                    type='password'
                    placeholder={localizedMessage}
                    name='password'
                    value={password}
                    onChange={this.onFieldChange}
                    className='form-control'
                    data-test='password'
                  />
                )}
              </LocalizedMessage>
              {
                errors.password ? (
                  <div
                    className='m-t-xs'
                  >
                    <span className='text-danger'>
                      {errors.password.message}
                    </span>
                  </div>
                )
                  : null
              }
            </div>

            <button
              type='submit'
              className='btn btn-primary block full-width m-b'
              data-test='login'
            >
              <LocalizedMessage
                id='auth.form.submitBtn'
              />
            </button>
          </form>
          <div>
            <LangSwitcher />
          </div>
        </div>
      </div>
    );
  }
}

export default Auth;
