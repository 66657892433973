import SweetAlert from '@sweetalert/with-react';
import alert from 'helpers/alert';
import { startLoading, stopLoading } from 'modules/loader';
import { localizeMessage } from 'components/LocalizedMessage';
import history from '../history';
import store from '../store';

export const serialize = (obj) => {
  const str = [];

  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  }

  return str.join('&');
};

const checkStatus = async (response, showError) => {
  if (response.status === 0) {
    return response;
  }

  if (
    response.status < 200 ||
    response.status >= 500
  ) {
    let errorMessage = response.statusText || (response.status >= 500 ? 'Internal server error' : 'Unknown error');
    let jsonResponse = null;

    if (
      response.status >= 500 &&
      response.headers.get('content-type') &&
      response.headers.get('content-type').toLowerCase().indexOf('application/json') > -1
    ) {
      jsonResponse = await parseJSON(response);

      if (jsonResponse.message) {
        errorMessage = jsonResponse.message;
      }
    }

    const error = new Error(errorMessage);
    error.response = response || null;

    if (jsonResponse) {
      error.jsonResponse = jsonResponse;
    }

    if (showError) {
      alert.error(errorMessage);
      error.withoutAlerts = true;
    }

    throw error;
  }

  if (response.status >= 400 && response.status < 500) {
    const jsonResponse = await parseJSON(response);

    const error = new Error(jsonResponse.message || response.statusText);
    error.response = response || null;
    error.jsonResponse = jsonResponse || null;

    throw error;
  }

  if (
    response.status !== 204 &&
    response.headers.get('content-type') &&
    response.headers.get('content-type').toLowerCase().indexOf('application/json') > -1
  ) {
    return parseJSON(response);
  }

  return response;
};

const parseJSON = response => response.json();

export const fetchResponse = async (url, params = {}, showError = true) => {
  const { headers, body, initController, withoutLoader = false, ...otherParams } = params;

  if (!withoutLoader) {
    store.dispatch(startLoading());
  }

  try {
    const responseData = {
      headers: headers(),
      ...otherParams,
    };

    if (body) {
      responseData.body = typeof body === 'function' ? body() : body;
    }

    if (typeof initController === 'function' && AbortController) {
      const controller = new AbortController();

      responseData.signal = controller.signal;

      initController(controller);
    }

    const response = await fetch(url, responseData);

    const status = await checkStatus(response, showError);

    if (!withoutLoader) {
      store.dispatch(stopLoading());
    }

    return status;
  } catch (error) {
    if (!withoutLoader) {
      store.dispatch(stopLoading());
    }

    return handleErrorRequest(error, () => fetchResponse(url, params, showError), showError);
  }
};

const handleErrorRequest = async (error, retry, showError) => {
  const errorMessage = error.message || 'Request error';

  if (['access is denied', 'access denied'].includes(errorMessage.toLowerCase())) {
    setTimeout(() => history.replace('/app/auth'), 500);
    alert.error(localizeMessage({ id: 'errors.errorAccessDenied' }));
    error.withoutAlerts = true;

    throw error;
  }

  if (
    error.response &&
    error.response.status &&
    error.response.status !== 200 &&
    (
      error.response.status < 400 ||
      error.response.status >= 500
    )
  ) {
    if (!error.jsonResponse) {
      return new Promise((resolve, reject) => {
        SweetAlert({
          text: localizeMessage({
            id: 'errors.errorLoadingDataWithMessage',
          }, {
            errorMessage,
          }),
          buttons: {
            confirm: localizeMessage({ id: 'yes' }),
            cancel: localizeMessage({ id: 'no' }),
          },
        })
          .then(isConfirm => {
            if (isConfirm) {
              resolve(retry());
            } else {
              reject(error);
            }
          });
      });
    }
    throw error;
  } else if (errorMessage === 'Failed to fetch') {
    if (showError) {
      alert.error(
        localizeMessage({
          id: 'errors.errorConnection',
        }),
      );

      error.withoutAlerts = true;
    }

    throw error;
  } else {
    if (!showError) throw error;

    if (errorMessage.includes('Invalid refresh token')) {
      alert.error(localizeMessage({ id: 'errors.badToken' }));
    } else {
      alert.error(errorMessage);
    }

    error.withoutAlerts = true;
    throw error;
  }
};
